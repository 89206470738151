import loadable from '@loadable/component';
import React from 'react';

import RDMD, { TOC as RDMDToc } from './rdmd';

const RMDX = loadable(() => import('@ui/RDMD/rmdx'));
const RMDXToc = loadable(() => import('@ui/RDMD/rmdx'), {
  resolveComponent: components => components.TOC,
});

// eslint-disable-next-line react/prop-types
const MarkdownRenderer = props => (props?.opts?.mdx ? <RMDX {...props} /> : <RDMD {...props} />);

// eslint-disable-next-line react/prop-types
export const TOC = props => (props?.opts?.mdx ? <RMDXToc {...props} /> : <RDMDToc {...props} />);

export default MarkdownRenderer;
